/** @format */

// /** @format */

import React from 'react'
// import VierNullVier from '../assets/images/illustrations/404.svg'
// import {SvgImage, ContainerWrapper} from '../components'
// Containers

export default () => (
  <h1>404!</h1>
  // <SvgImage src={VierNullVier} dWidth={700} />
)
